@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./scorestrip/scorestrip";
@forward './standing/home-standing';
@forward "./squad/home-squad";
@forward "./listing/common";
@forward "./listing/home";
@forward "./showcase/showcase";
@forward "./shop/home-shop";
@forward "./scorestrip/scorestrip-live";
.waf-component {
    @extend %p-6-2;
    .waf-head {
        @extend %pb-4;
    }
}
.waf-showcase,
.waf-scorestrip.widget-layout-08,
.waf-photos-listing {
    @include background(null, "pattern/white-bg.png", top / cover no-repeat);
}
.breadcrumb-wrap {
    @extend %d-none;
}
.waf-fanbanner {
    .banner-section {
        @extend %gap-3;
        @extend %flex-column;
    }
}
@include mq(col-md) {
    .waf-fanbanner {
        .banner-section {
            flex-direction: row;
        }
        .banner-item {
            width: 50%;
        }
    }
}