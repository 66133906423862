@use "../config/" as *;

.waf-scorestrip {
    &:empty{
        @extend %d-none;
    }
    &.widget-layout-10 {
        @extend %py-4;
        @extend %px-2;
        @include background(null , "pattern/mobile/live-scorestrip-bg.png", center / 100% 100% no-repeat);
        .waf-head,.shortname,.run-rate,.end-match-footer,.team-venue,.countdown-venue-wrapper,.swiper-button {
            @extend %d-none;
        }
        .live {
            .status {
                @extend %accent-900-bg;
                @extend %pure-white-900;
                &:before {
                    content: '';
                    width: 1.5rem;
                    height: 1.5rem;
                    @include background(null , "svg/live-blinker.svg", center / cover no-repeat);
                }
            }
        }
        .upcoming {
            .status {
                @extend %neutral-800;
                @extend %neutral-5-bg;
            }
            .live-match-footer {
                @extend %d-none;
            }
        }
        .match {
            &-info {
                flex-wrap: wrap;
                row-gap: var(--space-3);
                @extend %flex-c-n;
            }
            &-title {
                @extend %d-none;
            }
            &-status {
                order: -1;
                @extend %flex-c-n;
                @extend %w-100;
                .status {
                    width: max-content;
                    height: 3rem;
                    border-radius: .2rem;
                    @extend %gap-1;
                    @extend %relative;
                    @extend %px-2;
                    @extend %flex-n-c;
                    @extend %uppercase;
                    @extend %font-14-pb;
                }
            }
            &-time {
                @extend %pl-3;
                @extend %relative;
                &::before {
                    content: '';
                    left: .75rem;
                    width: .2rem;
                    @extend %h-70;
                    @extend %primary-50-bg;
                    @extend %pos-y-center;
                }
                .meta {
                    @extend %primary-50;
                    @extend %capitalize;
                    @extend %font-14-pb;
                }
                .time {
                    @extend %d-none;
                }
            }
            &-number {
                .text,
                .number {
                    @extend %primary-50;
                    @extend %capitalize;
                    @extend %font-14-pb;
                }
            }
            &-venue {
                @extend %mt-2-neg;
                @extend %text-center;
                @extend %w-100;
                .text {
                    @extend %primary-50;
                    @extend %font-14-pm;
                }
            }
        }
        .btn-scorecard {
            width: 20rem;
            height: 4.8rem;
            @extend %half-radius;
            @extend %flex-c-c;
            @extend %tertiary-600-bg;
            .btn-text {
                @extend %font-14-pb;
                @extend %neutral-800;
                @extend %uppercase;
            }
        }
        .card {
            &-head {
                border-bottom: .1rem solid var(--neutral-300);
                @extend %pb-4;
            }
            &-action-footer {
                @extend %flex-c-n;
            }
            &-body {
                border-bottom: .1rem solid var(--neutral-300);
                @extend %py-4;
            }
            &-item {
                @extend %pb-4;
            }
            &-footer {
                @extend %my-4;
                .team-status {
                    background: linear-gradient(90deg, rgba(6, 36, 95, 0) 0%, rgba(6, 36, 95, 0.423847) 8.2%, #06245F 50%, rgba(6, 36, 95, 0.374168) 93.17%, rgba(6, 36, 95, 0) 100%);
                    @extend %p-2;
                    @extend %text-center;
                    .text {
                        @extend %text-center;
                        @extend %primary-50;
                        @extend %font-14-pm;
                    }
                }
            }
        }
        .innings-info {
            @extend %mb-4;
            @extend %gap-3;
            @extend %flex-column;
            .head {
                height: 3rem;
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                @extend %flex-n-c;
                @extend %px-3;
                @extend %secondary-900-bg;
                .text {
                    @extend %pure-white-900;
                    @extend %capitalize;
                    @extend %font-14-pb;
                }
            }
            .body {
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                @extend %p-3;
                @extend %primary-900-bg;
            }
        }
        .player {
            &-data {
                @extend %py-2;
                @extend %gap-1;
                @extend %flex;
            }
            &-thumbnail,&-team {
                @extend %d-none;
            }
            &-info {
                width: 50%;
                &.active {
                    @extend %relative;
                    @extend %pl-3;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        @extend %success-900;
                        @include icon(chevron-right,10);
                        line-height: 2.2rem;
                    }
                }
            }
            &-info-content {
                @extend %gap-1;
                @extend %flex-n-c;
            }
            &-score {
                flex-shrink: 0;
                .runs,.score {
                    @extend %font-12-pb;
                    @extend %tertiary-600;
                }
            }
            &-name {
                line-height: 1.4rem;
                .name {
                    @extend %capitalize;
                    @extend %pure-white-900;
                    @extend %font-14-pm;
                }
            }
        }
        .text-vs {
            border-radius: .3rem;
            padding: .3rem;
            @extend %pure-white-900;
            @extend %font-12-pb;
            @extend %uppercase;
            @extend %accent-900-bg;
        }
        .team {
            width: 45%;
            @extend %flex;
            @extend %gap-2;
            &-current,&-won {
                .team-img {
                    border: .2rem solid var(--tertiary-600);
                }
                .team-score {
                    .score {
                        @extend %tertiary-600;
                    }
                }
            }
            &-wrap {
                @extend %flex;
            }
            &-divider {
                width: 10%;
                @extend %relative;
                @extend %flex-c-c;
                &:before {
                    content: '';
                    width: .1rem;
                    z-index: var(--z-scorestrip-border);
                    @extend %h-100;
                    @extend %pos-x-center;
                    @extend %primary-50-bg-5;
                }
            }
            &-info {
                width: 5rem;
                @extend %gap-2;
                @extend %flex-column-fe-n;
            }
            &-b {
                flex-direction: row-reverse;
                .team-info {
                    align-items: flex-end;
                    @extend %text-right;
                }
                .team-extra {
                    @extend %text-right;
                }
            }
            &-img {
                width: 5rem;
                height: 5rem;
                flex-shrink: 0;
                @extend %circle-radius;
                @extend %neutral-200-bg;
                @extend %p-1;
                @extend %flex-c-c;
            }
            &-name {
                width: 12rem;
                .name {
                    @extend %capitalize;
                    @extend %pure-white-900;
                    @extend %font-12-pb;
                }
            }
            &-score {
                .score  {
                    @extend %primary-50;
                    @extend %font-18-pb;
                }
            }
            &-extra {
                .overs {
                    @extend %primary-50;
                    @extend %font-12-pm;
                }
            }
        }
        .super-over {
            .team-score {
                @extend %relative;
                @extend %gap-1;
                @extend %flex-n-c;
                .score {
                    font-size: 1.6rem;
                }
                &:not(:last-child) {
                    .score {
                        font-size: 1.4rem;
                    }
                    &::after {
                        content: '';
                        height: .1rem;
                        @extend %w-100;
                        @extend %pure-white-900-bg-3;                        
                        @include position(null,null,-.3rem,0);
                    }
                }
            }
            .team-data-wrap {
                @extend %gap-1;
                @extend %flex-column;
            }
            .team-b {
                .team-score {
                    flex-direction: row-reverse;
                }
            }
        }
        .swiper-pagination  {
            justify-content: center;
            @extend %d-none;
        }
    }
}

@include mq(col-md) {
    .waf-scorestrip {
        &.widget-layout-10 {
            @include background(null , "pattern/live-scorestrip-bg.png", center / cover no-repeat);
            .team {
                align-items: center;
                &-info {
                    width: auto;
                    flex-direction: row-reverse;
                    align-items: center;
                }
                &-name {
                    width: 11rem;
                    .name {
                        font-size: 1.4rem;
                    }
                }
                &-b {
                    .team-info {
                        align-items: center;
                        flex-direction: row;
                    }
                }
                &-img {
                    width: 7rem;
                    height: 7rem;
                }
            }
            .player {
                &-name {
                    .name {
                        font-size: 1.6rem;
                    }
                }
                &-score {
                    .runs,.score {
                        font-size: 1.6rem;
                    }
                }
            }
            .swiper-button {
                display: none;
                &-prev {
                    right: var(--space-8);
                }
                &-next {
                    right: var(--space-0);
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-scorestrip {
        &.widget-layout-10 {
            padding-block: var(--space-6);
            .layout-wrapper {
                max-width: 80%;
            }
            .card-footer {
                .team-status {
                    padding-block: 1.2rem;
                    .text {
                        font-size: 1.8rem;
                    }
                }
            }
            .match {
                &-number {
                    .text,.number {
                        font-size: 1.6rem;
                    }
                }
                &-time {
                    .meta {
                        font-size: 1.6rem;
                    }
                }
                &-venue {
                    .text {
                        font-size: 1.6rem;
                    }
                }
            }
            .team {
                gap: var(--space-4);
                &-img {
                    width: 10rem;
                    height: 10rem;
                }
                &-score {
                    .score {
                        font-size: 3.2rem;
                    }
                }
                &-extra {
                    .overs {
                        font-size: 1.6rem;
                    }
                }
                &-name {
                    .name {
                        font-size: 1.8rem;
                    }
                }
            }
            .innings-info {
                flex-direction: row;
                margin-bottom: var(--space-6);
                .body {
                    padding-block: 0;
                }
            }
            .player {
                &-data {
                    padding-block: 0;
                    gap: 0;
                    flex-direction: column;
                }
                &-info {
                    width: 100%;
                    padding-block: var(--space-2);
                    padding-left: var(--space-4);
                    &.active {
                        padding-left: var(--space-4);
                    }
                    &:not(:last-child) {
                        border-bottom: .1rem solid clr(primary-50,2);
                    }
                }
                &-details {
                    flex: 1;
                }
            }
            .super-over {
                .team-score {
                    .score {
                        font-size: 2.4rem;
                    }
                    &:not(:last-child) {
                        .score {
                            font-size: 2rem;
                        }
                        .team-extra {
                            .overs {
                                font-size: 1.2rem;
                            }
                        }
                        &::after {
                            bottom: var(--space-1-neg);
                        }
                    }
                }
                .team-data-wrap {
                    gap: var(--space-2);
                }
            }
        }
    }
}
@include mq(col-xl) {
    .waf-scorestrip {
        &.widget-layout-10 {
            .layout-wrapper {
                max-width: 70%;
            }
        }
    }
}