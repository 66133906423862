@use "map" as *;
// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation ends
// showcase progressbar animaion starts
@keyframes progress {
  to {
    --per: 100%
  }
}
// showcase progressbar animaion ends
// squad scss animation 
@keyframes bounceInBottom {
  0% {
    transform: translate3d(0, 100%, 0)
  }
  25% {
    transform: translate3d(0, -14rem, 0)
  }
  40% {
    transform: translate3d(0, 1.2rem, 0)
  }
  60% {
    transform: translate3d(0, -.8rem, 0)
  }
  80% {
    transform: translate3d(0, .6rem, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
}
// squad scss animation 
@keyframes blinker {
  0% {
    background-color: var(--tertiary-600);
  }
  50% {
      background-color: var(--primary-900);
  }
  100% {
      background-color: var(--tertiary-600);
  }
}