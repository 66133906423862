@use "../config/" as *;
ul {
    list-style: none;
    padding-left: var(--space-0);
}
.header {
    height: var(--header-height);
    z-index: var(--z-header);
    @extend %fixed;
    @extend %w-100;
    @extend %primary-900-bg;
    @extend %pl-2;
    @extend %flex;
    @include border(3, accent-900, 10, bottom);
    &-menu {
        width: var(--window-inner-width);
        height: calc(100dvh - var(--header-height));
        top: -100%;
        right: 0;
        pointer-events: none;
        opacity: 0;
        @extend %fixed;
        @extend %primary-900-bg;
        @extend %transition;
        @extend %w-100;
        &:before {
            content: '';
            pointer-events: none;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-tl;
            @include background(null, "pattern/mobile/header-bg.png", top / cover no-repeat);
        }
    }
    &-wrapper {
        @extend %w-100;
    }
}
.site {
    &-header {
        @extend %gap-3;
        @extend %h-100;
        @extend %flex-sb-n;
    }
    &-logo {
        width: 7.8rem;
        flex-shrink: 0;
        &-link {
            height: 6.9rem;
            font-size: 0;
            z-index: var(--z-header-logo);
            @extend %relative;
            @extend %flex;
            @extend %w-100;
            @include background(null, "logo.png", center / contain no-repeat);
        }
    }
    &-utils {
        .sub-ul {
            border-bottom: .3rem solid var(--primary-500);
            @extend %px-4;
            @extend %w-100;
            @extend %primary-900-bg;
            @include position(var(--header-height), null, null, 0);
            @include dropdown(close);
        }
        .nav {
            &-item {
                height: 6.4rem;
                @extend %px-2;
                @extend %flex-n-c;
                &:not(:last-child) {
                    @include border(1, primary-500, 10, bottom);
                }
                &.active .nav-link {
                    @extend %tertiary-600;
                }
            }
            &-link {
                @extend %uppercase;
                @extend %pure-white-900;
                @extend %font-14-pb;
            }
        }
    }
    &-login {
        @include flex-config(flex, row-reverse, null, center);
        &-wrap {
            @extend %h-100;
            @extend %flex-n-c;
        }
        &[aria-expanded="true"] {
            & ~ .sub-ul {
                @include dropdown(open);
            }
            .user-image {
                @extend %tertiary-600-bg;
            }
        }
        .user-image {
            width: 3rem;
            height: 3rem;
            overflow: visible;
            padding: .8rem;
            @extend %flex-c-c;
            @extend %primary-800-bg;
            @extend %circle-radius;
        }
        .icon-profile {
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-0;
        }
    }
}
.lang-section {
    z-index: var(--z-header-language);
    @extend %d-none;
    // @extend %flex-n-c;
    .list-wrap {
        border-bottom: .3rem solid var(--primary-500);
        @extend %px-4;
        @extend %w-100;
        @extend %primary-900-bg;
        @include position(var(--header-height), null, null, 0);
        @include dropdown(close);
        .btn {
            height: 6.4rem;
            @extend %px-2;
            @extend %flex-n-c;
            &:not(:last-child) {
                @include border(1, primary-500, 10, bottom);
            }
            &-text {
                @extend %uppercase;
                @extend %pure-white-900;
                @extend %font-14-pb;
            }
            &.active .btn-text {
                @extend %tertiary-600;
            }
        }
    }
    .btn-lang {
        @extend %flex-n-c;
        &[aria-expanded="true"] {
            & ~ .list-wrap {
                @include dropdown(open);
            }
            .icon {
                @extend %neutral-800;
                @extend %tertiary-600-bg;
            }
        }
    }
    .btn-text {
        @extend %pure-white-900;
        @extend %uppercase;
        @extend %font-0;
    }
    .icon {
        width: 3rem;
        height: 3rem;
        @extend %pure-white-900;
        @extend %primary-800-bg;
        @extend %flex-c-c;
        @extend %circle-radius;
        &::before {
            @include icon(globe);
        }
    }
}
.btn-hamburger {
    width: var(--header-height);
    height: calc(var(--header-height) - .3rem);
    @extend %tertiary-600-bg;
    .line {
        fill: none;
        stroke: var(--neutral-800);
        stroke-width: 3;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
        &:first-child {
            stroke-dasharray: 60 207;
        }
        &:nth-child(2) {
            stroke-dasharray: 60 60;
        }
        &:last-child {
            stroke-dasharray: 60 207;
        }
    }
    &[aria-expanded="true"] {
        .line {
            stroke-width: 5;
            &:first-child {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
            }
            &:nth-child(2) {
                stroke-dasharray: 1 60;
                stroke-dashoffset: -30;
            }
            &:last-child {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
            }
        }
        & ~ .header-menu {
            top: var(--header-height);
            pointer-events: initial;
            opacity: 1;
        }
    }
}
.secondary-site {
    &-nav {
        @extend %h-100;
        @extend %flex-n-c;
        .nav {
            &-list {
                @include flex-config(flex, row-reverse, );
                @extend %gap-3;
            }
            &-item-highlight {
                .nav-link {
                    &::before {
                        content: '';
                        animation: blinker 2s ease-in-out infinite;
                    }
                }
            }
            &-item-shop {
                .nav-link {
                    &::before {
                        @include icon(shop);
                    }
                }
            }
            &-item-ticket {
                .nav-link {
                    &::before {
                        animation: blinker 2s ease-in-out infinite;
                        @include icon(ticket, 12);
                    }
                }
            }
            &-item-auction {
                .nav-link {
                    &::before {
                        @include icon(auction, 18);
                    }
                }
            }
            &-link {
                @extend %flex-n-c;
                @extend %pure-white-900;
                &:before {
                    width: 3rem;
                    height: 3rem;
                    @extend %flex-c-c;
                    @extend %primary-800-bg;
                    @extend %circle-radius;
                }
            }
            &-list {
                @extend %pl-0;
            }
            &-text {
                @extend %pure-white-900;
                @extend %uppercase;
                @extend %font-0;
            }
        }
    }
    &-wrap {
        @extend %ml-auto;
    }
}
.primary-site-nav {
    overflow-y: auto;
    height: calc(100% - 5rem);
    .nav {
        &-list {
            @extend %px-2;
        }
        &-item {
            @extend %pl-2;
            &:not(:last-child) {
                @include border(1, primary-500, 10, bottom);
            }
            &:has([aria-expanded="true"]) {
                isolation: isolate;
                @extend %relative;
                &::before {
                    content: '';
                    width: calc(100% + var(--space-4));
                    z-index: var(--z-header-nav-item);
                    @extend %primary-900-bg;
                    @extend %h-100;
                    @include position(null, null, 0, var(--space-2-neg));
                }
            }
            &.active .nav-text {
                @extend %tertiary-600;
            }
        }
        &-btn {
            @extend %transition;
            &::after {
                @extend %tertiary-600;
                @extend %transition;
                @include icon(plus);
            }
            &[aria-expanded="true"] {
                & ~ .sub-nav .sub-nav-item {
                    height: 4rem;
                }
                &::after {
                    content: '\e84a';
                }
            }
        }
        &-text {
            @extend %pure-white-900;
            @extend %capitalize;
            ;
            @extend %font-14-pb;
        }
        &-btn,
        &-link {
            height: 5.8rem;
            @extend %pure-white-900;
            @extend %flex-sb-c;
            @extend %w-100;
            @extend %h-100;
            @extend %relative;
            &:before {
                content: '';
                width: .2rem;
                left: var(--space-2-neg);
                @extend %pos-y-center;
                @extend %h-50;
                @extend %pure-white-900-bg;
            }
        }
    }
    .sub-nav {
        @extend %px-0;
        &-item {
            height: 0;
            @extend %hidden;
            @extend %transition;
            &.active {
                .sub-nav-text {
                    @extend %tertiary-600;
                }
            }
        }
        &-link {
            @extend %flex-sb-c;
            @extend %w-100;
            @extend %h-100;
        }
        &-text {
            @extend %pure-white-900;
            @extend %capitalize;
            @extend %font-14-pb;
        }
    }
}
.social-site-wrap {
    position: fixed;
    bottom: 0;
    height: 5rem;
    @extend %primary-900-bg;
    @extend %w-100;
    .social {
        &-list {
            @extend %gap-5;
            @extend %flex-c-c;
            &::before {
                @extend %tertiary-600;
                @extend %font-14-pb;
                @include position(-3rem, null, null, 0);
            }
        }
        &-item {
            width: 3rem;
            height: 3rem;
            @extend %primary-700-bg;
        }
        &-link {
            &::before {
                font-size: 1.4rem;
            }
        }
    }
}
.social-global-wrap,
.social-site-wrap {
    .social {
        &-list {
            @extend %h-100;
        }
        &-item {
            @extend %circle-radius;
        }
        &-link {
            @extend %h-100;
            @extend %flex-c-c;
            &::before {
                font-family: $font-icon;
                @extend %pure-white-900;
            }
        }
        &-text {
            @extend %font-0;
        }
    }
}
.social-global-wrap {
    border-radius: var(--half-radius) 0 0 var(--half-radius);
    width: 3.4rem;
    transform: translateY(-50%);
    @include position(50%, 0, null, null, fixed);
    @extend %d-none;
    @extend %secondary-900-bg;
    .social {
        &-list {
            @extend %gap-2;
            @extend %py-2;
            @extend %flex-column-c-c;
        }
        &-item {
            width: 2.4rem;
            height: 2.4rem;
            @extend %secondary-800-bg;
        }
        &-link {
            &::before {
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
        }
    }
}
@include mq(col-md) {
    .sticky {
        .site-logo-link {
            height: 7rem;
        }
    }
    .header {
        padding-left: var(--space-0);
        &-menu {
            padding: var(--space-10);
            gap: var(--space-8);
            overflow-y: auto;
            height: calc(var(--window-inner-height) - var(--header-height));
            @include flex-config(flex, column, flex-start);
            &:before {
                z-index: var(--z-header-bg-pattern);
                background-attachment: fixed;
                height: calc(var(--window-inner-height) - var(--header-height));
                @include background(null, "pattern/header-bg.png", top / cover no-repeat);
            }
        }
    }
    .secondary-site-nav {
        .nav {
            &-link {
                gap: var(--space-2);
            }
            &-text {
                font: 700 1.4rem/1 $font-primary;
            }
            &-item-ticket,
            &-item-highlight {
                padding-right: var(--space-2);
                animation: blinker 2s ease-in-out infinite;
                border-radius: var(--half-radius);
                .nav-link {
                    gap: var(--space-1);
                    &::before {
                        animation: none;
                        background: transparent;
                    }
                }
            }
        }
    }
    .site {
        &-header {
            gap: var(--space-6);
            margin-left: calc(var(--container-white-space) + 5rem);
        }
        &-utils {
            .sub-ul {
                padding-inline: var(--space-2);
            }
            .nav {
                &-item {
                    height: 5rem;
                }
            }
        }
        &-login {
            gap: var(--space-2);
            &-wrap {
                position: relative;
            }
            &[aria-expanded="true"] {
                .user-image {
                    @include bg(primary-800);
                    @include color(pure-white-900);
                }
            }
            .icon-profile {
                font: 700 1.4rem/1 $font-primary;
            }
        }
        &-logo {
            width: 12.5rem;
            &-link {
                height: 10rem;
                transition: .6s;
            }
        }
    }
    .lang-section {
        position: relative;
        .btn-lang {
            gap: var(--space-2);
            &::before {
                order: 1;
                @include color(pure-white-900);
                @include icon(chevron-down);
            }
            &[aria-expanded="true"] {
                &::before {
                    content: '\e806';
                }
                .icon {
                    @include bg(primary-800);
                    @include color(pure-white-900);
                }
            }
        }
        .btn-text {
            font: 700 1.4rem/1 $font-primary;
        }
        .list-wrap {
            padding-inline: var(--space-2);
            .btn {
                height: 5rem;
            }
        }
    }
    .primary-site-nav {
        overflow: unset;
        height: auto;
        position: relative;
        width: 100%;
        &::after {
            content: '';
            width: 100%;
            height: .1rem;
            @include bg(primary-600);
            @include position(null, null, var(--space-2), var(--space-0));
        }
        .nav {
            &-list {
                padding-inline: var(--space-0);
                display: flex;
                flex-wrap: wrap;
                row-gap: var(--space-6);
                padding-bottom: var(--space-4);
            }
            &-item {
                width: 25%;
                &:not(:last-child) {
                    border-bottom: 0;
                }
                &:has([aria-expanded=true]) {
                    &::before {
                        content: unset;
                    }
                }
            }
            &-link {
                height: 4rem;
                &::before {
                    content: unset;
                }
            }
            &-btn {
                height: 4rem;
                cursor: auto;
                &::after {
                    content: unset;
                }
                &::before {
                    @include bg(tertiary-600);
                }
                &[aria-expanded="true"] {
                    &::after {
                        content: unset;
                    }
                }
            }
            &-text {
                text-transform: capitalize;
                @include color(tertiary-600);
            }
        }
        .sub-nav {
            margin-inline: var(--space-2-neg) var(--space-2);
            &-item {
                height: 4rem;
                padding-inline: var(--space-2);
                @include transition(500ms);
                &:not(:last-child) {
                    margin-bottom: var(--space-1);
                }
                &:hover {
                    border-radius: .3rem;
                    @include bg(pure-white-900, 2);
                }
            }
            &-text {
                text-transform: capitalize;
                @include transition(1000ms);
            }
        }
    }
    .social-site-wrap {
        position: unset;
        background: transparent;
        .social {
            &-list {
                position: relative;
                justify-content: flex-start;
                &::before {
                    content: '| Follow us on social';
                }
            }
        }
    }
    .social-global-wrap {
        display: block;
    }
}
@include mq(col-md) {
    .header-menu {
        justify-content: center;
    }
}