@use "../config/" as *;
:where(.waf-listing .waf-head) .head-tab a {
    max-width: 10rem
}
.waf-news-listing {
    .second-list {
        --_listing-title: 14;
        --_listing-line: 3;
        @include listing-card(horizontal);
    }
}
.waf-photos-listing {
    --_listing-line: 1;
    .article-content {
        position: unset;
    }
    .reaction-section {
        position: unset;
    }
    .views-section {
        @extend %d-none;
    }
    .item-type-icon {
        position: absolute;
        top: var(--space-3);
        right: var(--space-3);
        backdrop-filter: blur(2rem);
        width: max-content;
        height: 3rem;
        aspect-ratio: unset;
        @extend %neutral-50;
        @extend %neutral-900-bg-6;
        @extend %p-1-2;
        @extend %flex-c-c;
        @extend %gap-1;
        @extend %half-radius;
        @extend %font-14-pb;
        &::before {
            @include icon(image)
        }
    }
}
.waf-videos-listing {
    --_listing-line: 3;
    --_listing-title: 20;
    --_title-color: var(--neutral-50);
    --_listing-line-height: 24;
    --swiper-button-width: 4rem;
    --swiper-button-height: 3.4rem;
    --swiper-button-bottom: 0rem;
    --swiper-button-right: 2rem;
    --swiper-pagination-bottom: 8rem;
    --swiper-icon-size: 15;
    isolation: isolate;
    @extend %secondary-800-bg;
    @include background(var(--secondary-800), "pattern/mobile/home-videos-bg.png", top/cover);
    @extend %relative;
    background-size: 100% 100%;
    &::before {
        width: 26rem;
        height: 10rem;
        z-index: var(--z-home-video-right);
        pointer-events: none;
        @include background(null, "pattern/home-videos-right-bg.png", top right/cover no-repeat);
        @extend %pos-tr;
    }
    &::after {
        width: 30rem;
        height: 15rem;
        z-index: var(--z-home-video-left);
        pointer-events: none;
        @include background(null, "pattern/home-videos-left-bg.png", bottom left/cover no-repeat);
        @extend %pos-bl;
    }
    // .swiper-button-next {
    //     border-bottom-right-radius: 0;
    // }
    .waf-body {
        padding-bottom: 8rem;
    }
    .icon-time-wrapper {
        width: max-content;
        border-right: .1rem solid clr(neutral-50, 5);
        @extend %font-14;
        @extend %mr-2;
        @extend %pr-2;
        height: max-content;
        @extend %mt-2;
        .item-type-icon {
            @extend %d-none;
        }
    }
    .article {
        &-meta {
            width: max-content;
        }
        &-content {
            background-color: transparent;
            @extend %p-3-2;
            @extend %m-0;
        }
        &-item {
            border-radius: 0;
        }
        &-thumbnail {
            @extend %relative;
            .img-box {
                border-radius: 0;
            }
        }
    }
    .thumbnail-swiper {
        @extend %d-none;
    }
    .swiper {
        position: unset;
        @extend %mx-2-neg;
        &-pagination {
            padding-inline: 0;
        }
    }
}
@include mq(col-md) {
    .waf-news-listing {
        --_content-width: 41.2rem;
        .layout-wrapper {
            position: relative;
        }
        .first-list {
            --_listing-title: 24;
            --_listing-line: 3;
            --_listing-line-height: 30;
            margin-bottom: var(--space-3);
            .img-box {
                border-radius: var(--full-radius);
            }
            .article {
                &-item {
                    grid-column: span 3;
                }
                &-title {
                    margin-bottom: 1rem;
                }
                &-thumbnail {
                    width: 47%;
                    flex-shrink: 0;
                    margin-left: -10rem;
                }
                &-wrap {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
                &-content {
                    height: max-content;
                    margin-top: auto;
                    border-radius: var(--half-radius);
                    width: 50%;
                    &::after,
                    &::before {
                        content: "";
                        width: 5rem;
                        height: 8rem;
                        display: block;
                        position: absolute;
                        top: -8rem;
                        @include background(null, "svg/footer-pattern-1.svg", bottom/100% no-repeat);
                    }
                    &::before {
                        left: -1.1rem;
                    }
                    &::after {
                        right: -0.8rem;
                    }
                    & > a::before {
                        content: "";
                        width: calc(100% - 6.5rem);
                        left: 3rem;
                        height: 2rem;
                        position: absolute;
                        top: -2rem;
                        @include background(null, "svg/footer-pattern-2.svg", left/2rem 2rem space)
                    }
                }
            }
        }
    }
    .waf-photos-listing {
        .article {
            &-list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: var(--space-2);
                @include listing-card("overlay")
            }
            &-content {
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(0, 100%);
                transition: transform .3s ease-in;
                .views-section {
                    position: fixed;
                }
            }
            &-thumbnail {
                img {
                    transform: scale(1.1);
                    transition: .3s transform ease-in;
                    filter: brightness(.9);
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
            &-item {
                height: 100%;
                margin: 0;
                transform: scale(1);
                &:nth-child(2) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
                &:hover {
                    .article-content {
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
    .waf-videos-listing {
        isolation: isolate;
        padding: 0;
        --_listing-title: 32;
        --_listing-line-height: 38;
        --swiper-button-bottom: 7rem;
        --swiper-button-right: calc(50% - 2rem);
        .waf-head {
            padding: 2rem;
        }
        .waf-body {
            margin-bottom: 0;
        }
        .swiper-pagination {
            display: none;
        }
        .layout-wrapper {
            max-width: unset;
        }
        .article-list {
            gap: 0;
        }
        .preview-swiper .article {
            &-item {
                margin-bottom: 0;
            }
            &-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 40%;
                height: 100%;
                border-radius: 0;
                align-content: flex-end;
                padding-bottom: 7rem;
                padding-left: var(--container-white-space);
                padding-right: 5rem;
                .reaction-section {
                    margin-left: 0;
                }
                &::before {
                    width: var(--window-inner-width);
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: linear-gradient(90deg, clr(secondary-800) 22.22%, clr(secondary-800, 7) 35.57%, transparent 40.6%);
                    z-index: var(--z-patterns);
                }
            }
            &-meta {
                margin-right: var(--space-2);
                border-right: .1rem solid clr(neutral-50, 5);
                padding-right: var(--space-2);
            }
            &-thumbnail {
                width: 80%;
                margin-left: auto;
            }
            &-title {
                margin-bottom: var(--space-6);
            }
        }
        .swiper {
            margin: 0;
        }
    }
}
@include mq(col-lg) {
    .waf-news-listing {
        .waf-head {
            position: absolute;
            top: 0;
            left: 0;
            width: max-content;
            z-index: var(--z-waf-head);
            .head-wrap {
                display: block;
            }
            .title {
                margin-bottom: var(--space-3);
            }
        }
        .first-list {
            .article-wrap {
                justify-content: space-between;
            }
            .article-thumbnail {
                width: 65%;
            }
            .article-content {
                padding-block: var(--space-5);
                width: var(--_content-width);
                padding-inline: var(--space-4);
                margin-left: var(--space-2);
            }
        }
    }
    .waf-videos-listing {
        background: var(--secondary-800);
        &::before {
            content: '';
        }
        &::after {
            content: '';
        }
        .waf-head {
            padding: 0;
            position: absolute;
            left: max(var(--container-white-space), var(--container-white-space)*-1);
            top: 5rem;
            z-index: var(--z-waf-head);
            .title {
                margin-bottom: var(--space-5);
            }
            .head-wrap {
                width: max-content;
                display: block;
            }
        }
        .preview-swiper {
            .article-content {
                left: min(-1 * var(--container-white-space), 0);
                padding-left: max(var(--container-white-space), 2rem);
            }
            .item-type-video {
                .item-type-icon {
                    width: 8rem;
                    height: 8rem;
                    &::before {
                        font-size: 2.6rem;
                    }
                }
            }
        }
        .thumbnail-swiper {
            width: 50%;
            position: absolute;
            bottom: 7rem;
            right: 0;
            display: block;
            .article-content {
                display: none;
            }
            .swiper-button-next,
            .swiper-button-prev,
            .swiper-pagination {
                display: none;
            }
        }
    }
    .waf-photos-listing {
        .article {
            &-list {
                gap: var(--space-4);
            }
        }
    }
}
@include mq(col-xl) {
    .waf-videos-listing {
        .waf-head {
            top: 9rem;
        }
    }
}