@use "../config/" as *;

.waf-breadcrumb {
    @extend %p-0;
    @extend %neutral-200-bg;
    .breadcrumb {
        &-list {
            list-style: none;
            padding-block: 1.2rem;
            flex-wrap: wrap;
            @extend %mb-0;
            @extend %px-3;
            @extend %pl-0;
            @extend %flex-c-n;
        }
        &-item {
            display: inline;
            a {
                @extend %neutral-700;
                @extend %font-12-pm;
                span  {
                    @extend %neutral-700;
                    @extend %font-12-pm;
                }
            }
            &:last-child {
                span {
                    @extend %neutral-800;
                    @extend %font-14-pb;
                }
            }
            &:not(:last-child) {
                @extend %relative;
                &::after {
                    @extend %px-2;
                    @extend %neutral-700;
                    @include icon(chevron-right,12);
                }
            }
            &:nth-child(3) {
                @extend %w-100;
                @extend %text-center;
            }
        }
    }
}
@include mq(col-md) {
    .waf-breadcrumb {
        .breadcrumb {
            &-list {
                justify-content: flex-start;
                padding-inline: 0;
            }
            &-item {
                a {
                    font-size: 1.4rem;
                    span  {
                        font-size: inherit;
                    }
                }
                &:nth-child(3) {
                    width: auto;
                    text-align: left;
                }
            }
        }
    }
}