@use "../config" as *;
.filter-section {
    position: absolute;
    right: var(--space-2);
    z-index: var(--z-filter);
    .btn-resetfilter,
    .dropdown-close,
    .filter-wrap {
        @extend %d-none;
    }
    .btn-more {
        width: 4.4rem;
        height: 4.4rem;
        @extend %half-radius;
        @extend %secondary-800-bg;
        @extend %flex-c-c;
        &::before {
            @extend %pure-white-900;
            @include icon(update-filter, 14);
        }
        .btn-text {
            @extend %pure-white-900;
            @extend %capitalize;
            @extend %font-0;
        }
    }
    .more-filters {
        height: calc(var(--window-inner-height) - var(--header-height));
        @include position(var(--header-height), -100%, null, null, fixed);
        @extend %transition;
        @extend %w-100;
        @extend %neutral-50-bg;
        .head {
            height: 4.4rem;
            @extend %flex-sb-c;
            @extend %primary-700-bg;
            @extend %px-2;
            .title {
                @extend %capitalize;
                @extend %pure-white-900;
                @extend %font-14-pb;
            }
        }
        .body {
            height: calc(100% - 12.8rem);
            @extend %overflow;
            @include custom-scroll;
        }
        .footer {
            @extend %my-4;
            @extend %gap-3;
            @extend %px-2;
            @extend %flex;
        }
        .btn-cancel {
            border: .1rem solid var(--pure-white-900);
            border-radius: .3rem;
            width: 2rem;
            height: 2rem;
            @extend %flex-c-c;
            @extend %pure-white-900;
            &::after {
                @include icon(close, 12);
            }
            .btn-text {
                @extend %font-0;
            }
        }
        .btn-applyfilter,
        .btn-resetfilter {
            height: 4.4rem;
            flex: 1;
            @extend %half-radius;
            @extend %font-14-pb;
            @extend %flex-c-c;
            .btn-text {
                @extend %neutral-800;
                @extend %font-14-pb;
                @extend %uppercase;
            }
        }
        .btn-applyfilter {
            border: .1rem solid var(--tertiary-600);
            @extend %tertiary-600-bg;
        }
        .btn-resetfilter {
            border: .1rem solid var(--primary-500);
            @extend %transparent-bg;
        }
    }
    &.active {
        .more-filters {
            right: 0;
        }
    }
    .waf-select-box {
        @extend %relative;
        @extend %pl-4;
        @extend %pr-3;
        @extend %py-3;
        &::before {
            content: "";
            height: 0.1rem;
            width: calc(100% - var(--space-4));
            @extend %neutral-300-bg;
            @include position(null, null, 0, var(--space-2));
        }
        &.active {
            .select-box-wrap {
                @extend %d-block;
            }
            .selected-title {
                &::after {
                    transform: rotate(180deg);
                }
            }
            &::after {
                content: '';
                width: .2rem;
                left: var(--space-2);
                height: calc(100% - var(--space-6));
                @extend %tertiary-600-bg;
                @extend %pos-y-center;
            }
        }
        .selected-title {
            cursor: pointer;
            @extend %pr-6;
            @extend %gap-2;
            @extend %relative;
            @extend %font-16-pb;
            @extend %w-100;
            @extend %h-100;
            @extend %flex-column-c-n;
            .title {
                @extend %capitalize;
                @extend %m-0;
                @extend %neutral-800;
                @extend %font-14-pb;
            }
            .sub-title {
                @extend %capitalize;
                @extend %font-12-pr;
            }
            &::after {
                transition: 0.3s;
                @include position(null, var(--space-2));
                @include icon(chevron-down, 14);
            }
        }
        .select-box-wrap {
            z-index: var(--z-filter-dropdown);
            top: 100%;
            transition: 0.3s;
            @extend %neutral-50-bg;
            @extend %w-100;
            @extend %d-none;
            @extend %px-2;
            @extend %pos-tl;
        }
        .select-list {
            max-height: 40rem;
            overflow-x: hidden;
            @extend %pl-0;
            @include custom-scroll;
            .list-item {
                height: 4.6rem;
                @extend %flex-n-c;
                @extend %capitalize;
                @extend %neutral-700;
                @extend %font-12-pm;
                @extend %w-100;
                @extend %relative;
                @extend %d-block;
                @extend %px-2;
                @extend %text-left;
                &:not(:last-child) {
                    &::before {
                        content: "";
                        height: 0.1rem;
                        @extend %w-100;
                        @extend %neutral-300-bg;
                        @extend %pos-bl;
                    }
                }
                // &.active {
                //     @extend %neutral-100-bg;
                //     @extend %pure-white-900;
                // }
                a {
                    color: inherit;
                    font-size: inherit;
                    @extend %d-block;
                }
            }
        }
    }
}
@include mq(col-md) {
    .filter-section {
        right: var(--container-white-space);
        .more-filters {
            .footer {
                flex-direction: row-reverse;
            }
        }
    }
}
@include mq(col-lg) {
    .filter-section {
        z-index: var(--z-filter-desktop);
        .more-filters {
            box-shadow: -.4rem .2rem .4rem 0 clr(neutral-800, 3);
            width: 40rem;
        }
        .btn-more {
            width: 9rem;
            flex-direction: row-reverse;
            gap: var(--space-3);
            .btn-text {
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
}