@use "../config/" as *;
th,
td {
    border: 0.1rem solid hsl(var(--hsl-black) / .3);
    @extend %p-2-1;
}
table {
    min-width: 100%;
    border-collapse: collapse;
    border: 0.1rem solid hsl(var(--hsl-black) / .3);
}
.table {
    border: 0.1rem solid hsl(var(--hsl-black) / .3);
    border-collapse: collapse;
    @extend %w-100;
    thead {
        @extend %primary-600-bg;
        @extend %pure-white-900;
    }
    th,
    td {
        @extend %capitalize;
        @extend %text-left;
        @extend %p-2;
    }
    tr {
        @extend %relative;
        &:not(:last-child) {
            border-bottom: 0.1rem solid hsl(var(--hsl-black) / .3);
        }
        &:nth-child(even) {
            @extend %neutral-100-bg-1;
        }
    }
    &-row {
        @extend %w-100;
        @extend %flex-c-c;
    }
    &-head {
        @extend %neutral-100-bg;
    }
    &-left {
        @extend %neutral-100-bg;
        @extend %w-40;
    }
    &-right {
        @extend %neutral-100-bg;
        @extend %overflow;
        @extend %w-60;
    }
    &-data {
        flex-shrink: 1;
        height: 5rem;
        @extend %w-100;
        @extend %text-center;
        @extend %flex-c-c;
    }
    .text {
        @extend %pure-white-900;
        @extend %font-14-pb;
        @extend %uppercase;
    }
}