@use "../config" as *;
html {
    @extend %w-100;
    &.no-scroll,
    &.no-scroll body {
        height: var(--window-inner-height);
        touch-action: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        @extend %hidden;
    }
}
[v-cloak] {
    visibility: hidden;
}
.grecaptcha-badge {
    visibility: hidden;
}
:where(.waf-component) {
    padding: var(--space-4) var(--space-2);
    @extend %mb-4;
}
:where(.img-box) {
    padding-bottom: 75%;
    @extend %neutral-100-bg;
    @extend %half-radius;
    @extend %hidden;
}
img {
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    @extend %w-100;
}
[class^="img-"] {
    @extend %relative;
    img {
        @extend %h-100;
        @extend %pos-tl;
        &[alt] {
            font-size: 1.2rem;
            @extend %neutral-900-7;
        }
    }
}
@each $width,
$height in $aspect-ratio {
    .img-#{$width}by#{$height} .img-box {
        padding-bottom: calc(($height / $width) * 100%);
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend %m-0;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
.desktop {
    display: none;
}
.mobile {
    display: block;
}
@each $width in $flex-width {
    .m-w-#{$width} {
        width: calc($width * 1%);
        &-gap {
            width: calc((#{$width * 1%} - var(--space-1)));
        }
    }
}
//project css start
/* web setup css done */
/* project common css start */
.main-wrap {
    padding-top: var(--header-height);
    min-height: calc(var(--window-inner-height) - var(--header-height));
}
// max-width style 
:where(.layout-wrapper) {
    max-width: var(--container-max-width);
    margin: auto;
}
// waf head common styles 
:where(.waf-head) {
    @extend %pb-4;
    .title {
        color: var(--_title-color, clr(neutral-800));
        @extend %font-18-pb;
    }
    .head-wrap {
        @extend %flex-sb-c;
    }
    .head-tab {
        li {
            border: 0.1rem solid var(--accent);
            list-style: none;
            @extend %text-center;
            @extend %uppercase;
            @extend %half-radius;
        }
        a {
            width: 20rem;
            transition: background-color 300ms;
            height: 3.8rem;
            @extend %flex-c-c;
            @extend %d-block;
            @extend %font-12-pb;
            @extend %neutral-800;
            @extend %tertiary-600-bg;
            @extend %half-radius;
            &:hover {
                @extend %tertiary-800-bg;
            }
        }
    }
}
// Primary tabs style
.primary-tabs {
    width: max-content;
    @extend %flex;
    @extend %neutral-200-bg;
    @extend %rounded-radius;
    &-action {
        @extend %p-2-4;
        @extend %neutral-300;
        @extend %font-14-pb;
    }
    &-active {
        .primary-tabs-action {
            border: .1rem solid clr(tertiary-800);
            @extend %rounded-radius;
            @extend %neutral-800;
            @extend %tertiary-600-bg;
        }
    }
}
// buttons style starts 
.loadmore {
    padding: var(--space-2) var(--space-8);
    @extend %half-radius;
    @extend %tertiary-600-bg;
    @extend %neutral-900;
    @extend %font-14-pb;
    @extend %uppercase;
}
.loadmore-wrap {
    margin: var(--space-4) auto;
    display: block;
    width: max-content;
}
.link-text {
    text-decoration: underline;
}
.scroll-top {
    z-index: var(--z-scrolltop);
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    @extend %d-none;
    .btn-top-scroll {
        width: 4rem;
        aspect-ratio: 1/1;
        @extend %relative;
        @extend %neutral-100-bg;
        @extend %half-radius;
        @extend %flex-c-c;
        &::after {
            @include icon(chevron-up, 16);
            @extend %pure-white-900;
            @extend %pos-center;
        }
    }
}
//safari workaround for base font size
@media only screen and (max-width: $tablet-min-width) {
    :root {
        -webkit-text-size-adjust: none;
    }
}
// buttons style starts 
@include mq(col-md) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    :where(.waf-head) {
        .title {
            font-size: 3.2rem;
        }
        .head-tab {
            a {
                font-size: 1.4rem;
                width: 11rem;
                height: 4.8rem;
            }
        }
    }
    @each $width in $flex-width {
        .w-#{$width} {
            width: calc($width * 1%);
            &-gap {
                width: calc((#{$width * 1%} - var(--space-1)));
            }
        }
    }
}