// this is kept just for color refence
$colors: (
	primary:( //
		50:(254, 100%, 95%), //#ebe5ff
		100:(226, 100%, 86%), //#b8c8ff
		200:(233, 84%, 80%), //#a1abf7
		300:(231, 59%, 70%), //#8593e0
		400:(232, 48%, 63%), //#737fce
		500:(230, 42%, 54%), //#5869bb
		600:(227, 41%, 47%), //#475ca9
		700:(219, 59%, 36%), //#264C93
		800:(226, 54%, 31%), //#24387a
		900:(220, 88%, 20%), //#06245F
		1000:(230, 42%, 54%), //#5A6ABB
		1100:(233, 84%, 80%), //#A2ACF7
		1200:(226, 100%, 86%), //#B8C9FF
	),
	secondary:( //
		50:(258, 100%, 93%), //#e6dbff
		100:(257, 100%, 92%), //#e2d6ff
		200:(254, 100%, 91%), //#dcd1ff
		300:(250, 100%, 88%), //#ccc2ff
		400:(247, 64%, 77%), //#a89fea
		500:(246, 42%, 65%), //#8880cb
		600:(245, 28%, 49%), //#605aa0
		700:(244, 33%, 41%), //#4b468b
		800:(239, 44%, 28%), //#282968
		900:(231, 100%, 13%), //#000a42
		1000:(257, 100%, 92%), //#E0D4FF
		1100:(250, 100%, 88%), //#CBC1FF
		1200:(231, 100%, 13%), //##000A44
	),
	tertiary:( //
		50:(44, 54%, 93%), //#f7f2e4
		100:(43, 83%, 84%), //#f8e5b4
		200:(43, 89%, 75%), //#f8d887
		300:(44, 94%, 65%), //#facd52
		400:(43, 95%, 59%), //#fac133
		500:(42, 96%, 55%), //#fab81e
		600:(39, 96%, 54%), //#faab19
		700:(34, 94%, 54%), //#f8981b
		800:(31, 94%, 54%), //#f88d1b
		900:(22, 93%, 52%), //#f66613
		1000:(0, 0%, 47%), //#777777
		1100:(39, 96%, 54%), //#FAAD1B
	),
	neutral: ( //
		5: (0, 0%, 96%), // #f6f6f6
		50: (0, 0%, 98%), //#fafafa
		100: (0, 0%, 96%), //#f5f5f5
		200: (0, 0%, 91%), //#e8e8e8
		300: (0, 0%, 71%), //#b5b5b5
		400: (0, 0%, 58%), //#949494
		500: (0, 0%, 47%), //#787878
		600: (0, 0%, 31%), //#4f4f4f
		700: (0, 0%, 24%), //#3d3d3d
		800: (0, 0%, 13%), //#212121
		900: (0, 0%, 0%), //#000000
		1000:(0, 0%, 71%), //#B6B6B6
		1100:(0, 0%, 91%), //#E7E7E7
		1200:(0, 0%, 15%), //#272727
		1300:(0, 0%, 24%), //#3E3E3E
	),
	accent: ( //
		100:(5, 95%, 56%), //#F93626;
		900: (357, 79%, 47%), //#d71923
	),
	success: ( //
		900: (145, 75%, 41%), //#1AB85B
	),
	warning: ( //
		900: (40, 100%, 48%), //#F5A500
	),
	error: ( //
		// 900: (360, 81%, 58%), //#EA3C3D
		900: (0, 100%, 71%) // #FF6969
	),
	pure-white: ( //
		900: (0, 0%, 100%), //#ffffff
	),
	spider:( //
		1s: (219, 59%, 36%), // #264C93
		2s: (357, 79%, 47%), // #D71921
		3s: (245, 28%, 49%), // #5F599F
		4s: (22, 93%, 52%), // #F66915
		5s: (42, 96%, 55%), // #FBBA1F
	),
	spider-webview:( //
		1s:(219, 59%, 36%), // #264C93
		2s:(357, 79%, 47%), // #D71921
		3s:(0, 0%, 13%), // #212121
		4s: (22, 93%, 52%), // #F66915
		5s: (34, 94%, 54%), // #F89919
	),
	shimmer:( //
		light: (0, 0%, 95%), // #f1f1f1
		dark: (0, 0%, 67%), // #ABABAB
	)
);
$aspect-ratio: (
	4 3,
	16 9,
	1 1,
	2 3,
	9 16,
	21 9,
	18 9,
	18 6,
	6 4,
	4 6
);
$flex-width: (
	10,
	20,
	25,
	30,
	33,
	40,
	50,
	60,
	70,
	80,
	100
);
$font-detail: (
	Montserrat:( //
		pr:(file-name:Montserrat-Regular, weight: 400, style:normal),
		pm:(file-name:Montserrat-Medium, weight: 500, style:normal),
		pb:(file-name:Montserrat-Bold, weight: 700, style:normal),
	),
	Philosopher:( //
		sr:(file-name:Philosopher-Regular, weight: 400, style:normal),
		sb:(file-name:Philosopher-Bold, weight: 700, style:normal),
	),
	Figtree:( //
		tr:(file-name:Figtree-Regular, weight: 400, style:normal),
		tm:(file-name:Figtree-Medium, weight: 500, style:normal),
		tsb:(file-name:Figtree-SemiBold, weight: 600, style:normal),
	),
	Oswald:( //
		qr:(file-name:Oswald-Regular, weight: 400, style:normal),
		qm:(file-name:Oswald-Medium, weight: 500, style:normal),
		qsb:(file-name:Oswald-SemiBold, weight: 600, style:normal),
		qb:(file-name:Oswald-Bold, weight: 700, style:normal),
	),
);
$directions: (
	t: -top,
	r: -right,
	b: -bottom,
	l: -left,
	x: -inline,
	y: -block
);
$flex-spacing: (
	-sa: space-around,
	-sb: space-between,
	-se: space-evenly,
	-fe: flex-end,
	-fs: flex-start,
	-c: center,
	-s: stretch,
	-u: unset,
	-n: null
);
$media-query: (
	col: 300px,
	col-sm: 576px,
	col-md: 768px,
	col-lg: 992px,
	col-xl: 1200px,
	col-xxl: 1400px
);
$zindex: (
	patterns:-1,
	header-logo: 1,
	header: 11,
	header-nav-item: -1,
	header-bg-pattern: -1,
	standing-bg: -1,
	standing-border: -1,
	scorestrip-border: -1,
	waf-head:3,
	header-language: 1,
	bg-gradient: -2,
	toggle-stats: 1,
	modal: 104,
	player-profile-bg: -1,
	player-profile-overseas: 1,
	player-profile-text: 1,
	white-pattern-bg: -1,
	player-profile-hide: -1,
	cookie-popup: 11,
	filter-dropdown: 5,
	filter: 6,
	filter-desktop: 11,
	contact-bg: -1,
	error-bg: -1,
	homesquad-pattern: -1,
	team-toggle: 2,
	user-profile-bg: -1,
	user-profile-setting: 1,
	form-dropdown: 1,
	user-profile-setting-web: 2,
	home-video-left: 1,
	home-video-right: 2,
	modal-close: 1,
	swiper-button: 1
);
$icons: (
	"arrow-left": "\e800",
	"arrow-right": "\e801",
	"arrow-up": "\e802",
	"arrow-down": "\e803",
	"chevron-left": "\e804",
	"chevron-right": "\e805",
	"chevron-up": "\e806",
	"chevron-down": "\e807",
	"facebook": "\e808",
	"filter": "\e809",
	"link": "\e80a",
	"play-store": "\e80b",
	"app-store": "\e80c",
	"tiktok": "\e80d",
	"whatsapp": "\e80e",
	"twitter": "\e851",
	"instagram": "\e810",
	"linkedin": "\e811",
	"pen": "\e812",
	"close": "\e813",
	"call": "\e814",
	"play": "\e815",
	"ticket": "\e877",
	"info": "\e817",
	"user": "\e818",
	"clock": "\e819",
	"search": "\e81a",
	"checkmark": "\e819",
	"eye": "\e81c",
	"eye-close": "\e81d",
	"youtube": "\f16a",
	"reset": "\e83c",
	"share": "\e81f",
	"plus": "\e865",
	"shop": "\e867",
	"minus": "\e84a",
	"globe": "\e849",
	"overseas":"\e824",
	"image":"\e825",
	"close-btn":"\e850",
	"circle-close": "\e870",
	"checkmarkbox": "\e871",
	"location":"\e82d",
	"email": "\e82c",
	"call-line": "\e82b",
	"update-filter": "\e853",
	"edit": "\e85d",
	"logout": "\e85e",
	"setting": "\e866",
	"varified": "\e864",
	"refresh": '\e836',
	"delete": '\e86f',
	"restore": '\e85f',
	"auction": '\e85c',
);
$social: (
	"facebook": "\e808",
	"youtube": "\f16a",
	"tiktok": "\e80d",
	"whatsapp": "\e80e",
	"twitter": "\e851",
	"instagram": "\e810",
	"linkedin": "\e811",
	"copylink": "\e84e",
	"play-store": "\e80b",
	"app-store": "\e80c",
);