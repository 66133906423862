@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		top: unset;
		bottom: var(--swiper-button-bottom, 2rem);
		left: unset;
		width: var(--swiper-button-width, 4rem);
		height: var(--swiper-button-height, 4rem);
		@extend %tertiary-600-bg;
		&.swiper-button-disabled {
			cursor: not-allowed;
			opacity: 1;
			@extend %neutral-200-bg;
		}
	}
	&-button-prev {
		border-radius: var(--half-radius) 0 0 var(--half-radius);
		right: calc(var(--swiper-button-right, 50%) + (var(--swiper-button-width) / 2));
		&::after {
			@include icon(chevron-left, var(--swiper-icon-size, 18));
		}
	}
	&-button-next {
		right: calc(var(--swiper-button-right, 50%) - (var(--swiper-button-width) / 2));
		border-radius: 0 var(--half-radius) var(--half-radius) 0;
		&::after {
			@include icon(chevron-right, var(--swiper-icon-size, 18));
		}
	}
	.swiper-pagination {
		@extend %px-2;
		@extend %text-left;
		@extend %flex;
		@extend %gap-2;
		&-bullet {
			opacity: 1;
			flex: 1;
			max-width: 6rem;
			height: 0.2rem;
			border-radius: 0;
			margin: var(--space-0);
			@extend %primary-500-bg;
		}
		&-bullet-active {
			@extend %tertiary-600-bg;
		}
	}
}