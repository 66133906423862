@use "../config/" as *;
.waf-standings {
    --_title-color: var(--pure-white-900);
    padding-bottom: var(--space-14);
    @extend %relative;
    &::before {
        content: '';
        z-index: var(--z-standing-bg);
        pointer-events: none;
        @extend %pos-tl;
        @extend %w-100;
        @extend %h-100;
        @include background(null, "pattern/mobile/home-standing-bg.png", top / cover no-repeat);
    }
    .waf-head {
        @extend %pb-3;
        .head-tab {
            bottom: var(--space-5);
            @extend %pos-x-center;
        }
    }
    .standing {
        &-swiper {
            overflow-x: auto;
            @extend %mx-2-neg;
            @extend %px-2;
        }
        &-list {
            width: max-content;
            @extend %pt-9;
            @extend %gap-3;
            @extend %flex;
        }
        &-item {
            flex-shrink: 0;
            flex-wrap: wrap;
            row-gap: var(--space-2);
            column-gap: var(--space-1);
            padding: var(--space-3) var(--space-3) var(--space-5);
            width: calc(70vw - .75rem);
            backdrop-filter: blur(4rem);
            @extend %relative;
            @extend %flex;
            @extend %full-radius;
            &::before {
                content: '';
                border: .1rem solid var(--primary-500);
                z-index: var(--z-standing-border);
                mask-image: linear-gradient(var(--primary-700) 80%, transparent);
                pointer-events: none;
                @extend %pos-tl;
                @extend %full-radius;
                @extend %w-100;
                @extend %h-100;
                @extend %primary-700-bg;
            }
            &.highlight {
                &::before {
                    border: .3rem solid var(--tertiary-600);
                }
            }
        }
        &-data,
        &-numbers {
            @extend %p-2;
            @extend %full-radius;
            @extend %neutral-900-bg-2;
        }
        &-data {
            width: calc(50% - .25rem);
            @extend %gap-1;
            @extend %flex-column-n-c;
            &.qualifier {
                @extend %tertiary-600-bg;
            }
        }
        &-numbers {
            @extend %gap-2;
            @extend %w-100;
            @include flex-config(flex, column-reverse, null, center);
        }
        &-number {
            @extend %pure-white-900;
            @extend %font-16-pb;
        }
        &-label {
            @extend %uppercase;
            @extend %secondary-200;
            @extend %font-12-pm;
        }
        &-name {
            @extend %capitalize;
            @extend %pure-white-900;
            @extend %font-14-pb;
        }
    }
    .short-name {
        @extend %d-none;
    }
    .team {
        align-items: flex-start;
        width: 100%;
        &-image {
            width: 9rem;
            height: 9rem;
            border: .2rem solid var(--primary-500);
            @extend %p-2;
            @extend %neutral-100-bg;
            @extend %circle-radius;
            @extend %hidden;
            @include position(var(--space-9-neg), null, null, var(--space-3));
            .team-logo {
                @extend %neutral-100-bg;
            }
        }
        &-name {
            width: 90%;
        }
    }
    .position {
        width: 100%;
        align-items: flex-end;
        padding: var(--space-0);
        @extend %transparent-bg;
        @extend %secondary-200;
        @extend %font-48-pb;
    }
    .qualifier {
        width: 2rem;
        height: 2rem;
        justify-content: center;
        padding: var(--space-0);
        line-height: 1rem;
        @extend %font-12-pb;
        @extend %uppercase;
        @include position(var(--space-20), var(--space-4), null, null);
    }
    .form-guide {
        background: linear-gradient(180deg, clr(neutral-900, 2) 43.25%, clr(neutral-900, 0) 100%);
        &-listing {
            @extend %gap-2;
            @extend %flex;
        }
        &-item {
            width: 2.4rem;
            height: 2.4rem;
            @extend %flex-c-c;
            @extend %circle-radius;
            &.win {
                @extend %success-900-bg;
                .standing-text {
                    @extend %neutral-800;
                }
            }
            &.lost {
                @extend %error-900-bg;
            }
            &.draw {
                @extend %neutral-500-bg;
            }
        }
        .standing-text {
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-12-pb;
        }
        .standing-label {
            text-transform: capitalize;
        }
    }
}
@include mq(col-md) {
    .waf-standings {
        padding-bottom: var(--space-6);
        &::before {
            @include background(null, "pattern/home-standing-bg.png", top / cover no-repeat);
        }
        .waf-head {
            padding-bottom: var(--space-5);
            .primary-tabs {
                margin-inline: auto var(--space-4);
            }
            .head-tab {
                position: unset;
                translate: unset;
            }
        }
        .standing-item {
            width: calc(33.33vw - .75rem);
        }
        .team {
            &-name {
                width: 95%;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-standings {
        padding-inline: var(--space-0);
        .standing {
            &-swiper {
                overflow: hidden;
                margin-inline: var(--space-0);
                padding-inline: var(--space-0);
            }
            &-list {
                width: auto;
            }
            &-item {
                width: calc(20% - 1.2rem);
            }
        }
    }
}