@use '../config/' as *;
.waf-shape-head {
    --_shape-head-top: var(--space-4-neg);
    @extend %hidden;
    .waf-head {
        @extend %pb-6;
        @extend %pt-2;
        @extend %mb-4;
        @extend %relative;
        &::before {
            content: "";
            width: var(--window-inner-width);
            position: absolute;
            top: var(--_shape-head-top);
            // left: calc(var(--container-white-space) * -1);
            left: min(var(--container-white-space)*-1 ,var(--container-white-space));
            bottom: 0;
            z-index: var(--z-patterns);
            @include background(clr(primary-900), "pattern/mobile/shape-head.png", center/cover);
        }
        .title {
            @extend %neutral-50;
            @extend %font-20;
        }
    }
}
@include mq(col-lg) {
    .waf-shape-head {
        .waf-head {
            padding: var(--space-8) 0 var(--space-4);
            .title {
                font-size: 3.2rem
            }
            &::before {
                @include background(clr(primary-900), "pattern/shape-head.png", center/cover);
            }
        }
    }
}