@use "../config/" as *;
// TODO: Home squad background animation
.waf-squad {
    --swiper-button-right: 2rem;
    --swiper-nav-bg: var(--tertiary-600);
    --swiper-nav-fg: var(--neutral-800);
    --_title-color: var(--neutral-50);
    @extend %neutral-50;
    @include background(clr(primary-900), "pattern/mobile/squad-bg.png", top/cover);
    .waf-head {
        @extend %mb-4;
        .primary-tabs {
            z-index: var(--z-team-toggle);
        }
    }
    .waf-body {
        @extend %pb-10;
    }
    .head-tab {
        position: absolute;
        bottom: 2.5rem;
        inset-inline: 0;
        margin: auto;
        a {
            margin-inline: auto;
        }
    }
    .swiper {
        @extend %mx-2-neg;
        @extend %px-2;
    }
    .squad-listing {
        padding-inline: var(--space-0);
    }
    .player {
        &-thumbnail {
            @extend %relative;
            img {
                width: 23.5rem;
                margin-right: var(--space-8);
                @extend %ml-auto;
            }
            &:before {
                content: '';
                height: 30rem;
                width: calc(100% + var(--space-4));
                z-index: var(--z-homesquad-pattern);
                @include background(null, "pattern/mobile/squad-roar-bg.png", bottom/cover no-repeat);
                @include position(null,null,var(--space-4),var(--space-2-neg));
            }
        }
        &-name {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            @include separator(90%);
            @extend %gap-1;
            @extend %tertiary-600;
            @extend %font-22-pb;
            @extend %neutral-900-bg-2;
            @extend %p-3;
            @extend %text-center;
            .name {
                display: inline;
            }
        }
        &-meta {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %neutral-900-bg-2;
            @extend %p-3;
            @extend %flex-c-c;
            @extend %gap-1;
        }
        &-role {
            @extend %flex-c-c;
            @extend %gap-1;
            &::after {
                content: "|";
                @extend %ml-1;
            }
            .role {
                @extend %font-14-pm;
                &:not(:last-child)::after {
                    opacity: 0.5;
                    content: "|";
                    @extend %primary-600;
                    @extend %ml-1;
                }
            }
        }
        &-stats {
            &-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                @extend %text-center;
                @extend %gap-1;
                @extend %my-2;
            }
            &-item {
                flex-direction: column-reverse;
                backdrop-filter: blur(4rem);
                @extend %flex;
                @extend %half-radius;
                @extend %neutral-900-bg-2;
                @extend %p-3;
                @extend %mb-2;
            }
            &-count {
                border-bottom: .1rem solid clr(primary-600, 6);
                @extend %tertiary-600;
                @extend %font-18-pb;
                @extend %mb-2;
                @extend %pb-2;
            }
            &-title {
                @extend %font-14-pm;
            }
        }
        &-country {
            @extend %flex;
        }
        &-content {
            background: linear-gradient(180deg, clr(primary-900, 5) 39.33%, transparent 100%);
            backdrop-filter: blur(4rem);
            @extend %mt-4-neg;
            @extend %p-2;
            @extend %half-radius;
        }
    }
    .country {
        &-flag {
            @extend %d-none;
        }
        &-name {
            @extend %font-14-pm;
        }
    }
    .overseas {
        &::after {
            position: absolute;
            top: 30%;
            left: 0;
            width: 2.8rem;
            height: 2.8rem;
            @include icon(overseas);
            @extend %neutral-900;
            @extend %flex-c-c;
            @extend %neutral-50-bg;
            @extend %circle-radius;
        }
    }
    .captain {
        &::before {
            position: absolute;
            top: calc(30% - 4rem);
            left: 0;
            width: 2.8rem;
            height: 2.8rem;
            content: "C";
            @extend %font-16-pb;
            @extend %flex-c-c;
            @extend %accent-900-bg;
            @extend %circle-radius;
        }
    }
    .card-action {
        grid-column: span 3
    }
    .view-more {
        border: .1rem solid clr(tertiary-600);
        max-width: 20rem;
        transition: .3s;
        @extend %mx-auto;
        @extend %half-radius;
        @extend %d-block;
        @extend %font-14-pb;
        @extend %uppercase;
        @extend %p-2;
        @extend %tertiary-600;
        &:hover {
            @extend %secondary-900;
            @extend %tertiary-600-bg;
        }
    }
    .swiper-button {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-squad {
        .layout-wrapper {
            max-width: 100%;
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        &.waf-component {
            padding-bottom: 0;
        }
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        @include background(clr(primary-900), "pattern/squad-bg.jpg", top/cover);
        .waf-body {
            padding-bottom: 0;
        }
        .swiper-wrapper {
            transition-timing-function: ease-in-out;
        }
        .swiper-slide {
            scale: 0.8;
            transition: 300ms;
            &-active {
                scale: 1;
            }
        }
        .swiper-button {
            display: flex;
            bottom: 6rem;
        }
        .layout-wrapper {
            display: flex;
            align-items: center
        }
        .head-tab {
            position: unset;
        }
        .waf-head {
            width: 30rem;
            flex-shrink: 0;
            align-self: flex-start;
            .head-wrap {
                display: block;
                a {
                    margin: 1.5rem 0 0;
                }
            }
        }
        .waf-body {
            width: calc(100% - 30rem)
        }
        .primary-tabs {
            position: absolute;
            top: 3rem;
            right: max(var(--container-white-space), 0rem);
        }
        .player {
            &-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
            &-thumbnail {
                width: 45%;
                max-width: unset;
                flex-shrink: 0;
                margin-inline: 0 auto;
                &::before {
                    content: unset;
                }
                img {
                    width: 100%;
                    margin-left: unset;
                    margin-right: unset;
                }
            }
            &-content {
                margin: 0;
                width: calc(55% - 4rem);
                flex-shrink: 0;
                margin-bottom: 8rem;
                padding: 2rem 2rem 3rem;
                // margin-top: 12rem
            }
            &-name {
                font-size: 3.2rem;
                min-width: max-content;
            }
            &-role {
                .role {
                    font-size: 1.8rem
                }
            }
            &-stats {
                &-title {
                    font-size: 1.8rem
                }
                &-count {
                    font-size: 2.4rem
                }
            }
        }
        .country-name {
            font-size: 1.8rem
        }
        .overseas {
            &::after {
                left: 45%;
                top: 20%;
            }
        }
        .captain {
            &::before {
                top: calc(20% - 4rem);
                left: 45%;
            }
        }
        .card-action {
            .view-more {
                height: 4.8rem;
                @include flex-config(flex,null,center,center);
            }
        }
    }
}