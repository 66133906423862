@use "../config" as *;

.cookie-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-cookie-popup);
    @extend %d-none;
    @extend %primary-900-bg-7;
    @extend %w-100;
    @extend %h-100;
    .cookie {
        &-wrap {
            position: fixed;
            bottom: var(--space-3);
            right: var(--space-3);
            width: calc(100% - var(--space-6));
            @extend %p-4;
            @extend %half-radius;
            @extend %neutral-50-bg;
        }
        &-head {
            @include position(var(--space-2-neg),var(--space-2-neg),null,null);
        }
    }
    .btn-close {
        width: 2.5rem;
        height: 2.5rem;
        @extend %flex-c-c;
        @extend %circle-radius;
        @extend %tertiary-600-bg;
        &::before {
            @extend %neutral-800;
            @include icon(close,12);
        }
        .btn-text {
            @extend %font-0;
        }
    }
    .btn-accept {
        height: 4.4rem;
        @extend %mt-3;
        @extend %half-radius;
        @extend %tertiary-600-bg;
        @extend %w-100;
        .btn-text {
            @extend %neutral-800;
            @extend %uppercase;
            @extend %font-14-pb;
        }
    }
    .text {
        @extend %neutral-800;
        @extend %font-14-pm;
    }
}
@include mq(col-md) {
    .cookie-popup {
        .cookie-wrap {
            inset-inline: 0;
            width: 50%;
            margin-inline: auto;
        }
    }
}
@include mq(col-lg) {
    .cookie-popup {
        .cookie-wrap {
            width: 50rem;
            right: var(--space-14);
            bottom: var(--space-14);
            margin-inline: auto 0;
        }
    }
}