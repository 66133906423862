@use "../config" as *;
.article-item {
    width: 100%;
}
// common style/card style
// used local variable so that we can change it's value on widget level or perent level
// how to use 
// .waf-news {
//     --_listing-line: 3;
//     --_listing-title: 20;
// }
:where(.waf-listing) {
    --z-listing: 2;
    --z-reaction: 3;
    .article {
        &-wrap {
            @extend %flex-column;
        }
        &-thumbnail {
            @extend %w-100;
        }
        &-item {
            @extend %relative;
            @extend %half-radius;
            @extend %mb-3;
            @extend %hidden;
        }
        &-title {
            color: currentColor;
            @extend %mt-0;
            @include truncate(var(--_listing-line, 2), var(--_listing-title, 18), var(--_listing-line-height, 20));
        }
        &-content {
            z-index: var(--z-listing);
            flex-wrap: wrap;
            gap: var(--space-2) 0;
            @extend %mt-2-neg;
            @extend %flex;
            @extend %half-radius;
            @extend %relative;
            @extend %w-100;
            @extend %p-3;
            @extend %primary-900-bg;
            @extend %neutral-50;
            a,
            .icon-b-share {
                color: currentColor;
            }
            > a {
                @extend %w-100;
            }
            .readmore {
                @extend %font-0;
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
            }
            .reaction-section {
                z-index: var(--z-reaction);
                width: 3rem;
                @extend %relative;
                @extend %mt-auto;
                @extend %ml-auto;
            }
            &-meta {
                .meta {
                    @extend %neutral-700;
                    &::after {
                        @extend %neutral-100-bg;
                    }
                }
            }
        }
        &-meta {
            width: calc(100% - 3.5rem);
            flex-wrap: wrap;
            @extend %p-0;
            @extend %mt-auto;
            @extend %mb-1;
            @extend %gap-1;
            @extend %flex-n-fs;
            .meta {
                @extend %relative;
                @extend %font-12-pm;
                @extend %m-0;
                &:not(:first-child) {
                    @extend %pr-2;
                    &:after {
                        content: "";
                        width: 0.1rem;
                        height: 1rem;
                        @include position-combo(y-center, 0);
                    }
                }
                &::after {
                    @extend %neutral-100-bg;
                }
                &-category,
                &-author {
                    max-width: 34%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @extend %hidden;
                    @extend %font-12;
                }
                &-date {
                    order: 1;
                    @extend %font-12;
                }
            }
        }
    }
    .waf-body {
        @extend %relative;
    }
    .filter-section {
        top: var(--space-16-neg);
        right: 0;
        .btn-more {
            width: 11rem;
            flex-direction: row-reverse;
            gap: var(--space-2);
            @extend %tertiary-600-bg;
            .btn-text {
                color: var(--neutral-800);
                font-weight: 700;
                font-size: 1.2rem;
                text-transform: uppercase;
            }
            &::before {
                color: var(--neutral-800);
            }
        }
    }
}
@include mq(col-md) {
    :where(.article-list) {
        @include grid(2, var(--space-4));
    }
    :where(.waf-listing) {
        .filter-section {
            .btn-more {
                .btn-text {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    :where(.article-list) {
        @include grid(3, var(--space-4));
        .article-meta .meta {
            font-size: 1.4rem
        }
    }
}