@use "../config/" as *;
.waf-showcase {
  padding: 0;
  --_content-line: 2;
  --_content-title: 22;
  --_content-line-height: 28;
  --_description-line: 3;
  --_description-title: 14;
  --_description-line-height: 20;
  --swiper-button-bottom: 0;
  --swiper-button-width: 4.1rem;
  --swiper-button-height: 3.4rem;
  --swiper-icon-size: 1.6rem;
  .img-box {
    border-radius: 0;
    @extend %relative;
    img {
      object-fit: contain;
      @extend %h-100;
    }
  }
  .article {
    &-list {
      gap: 0;
      @extend %flex;
    }
    &-item {
      flex-shrink: 0;
      border-radius: 0;
      @extend %hidden;
      @extend %w-100;
    }
    &-wrap {
      @extend %relative;
    }
    &-thumbnail {
      @extend %relative;
      &::before {
        content: '';
        pointer-events: none;
        z-index: 1;
        background-image: linear-gradient(to top, clr(pure-white-900, 5), transparent);
        @extend %pos-bl;
        @extend %w-100;
        @extend %h-20;
      }
    }
    &-content {
      padding-bottom: var(--space-15);
      @extend %flex-column;
      @extend %gap-3;
      @extend %w-100;
      @extend %px-3;
      @extend %pt-13;
      @extend %primary-900-bg;
      @extend %relative;
      &::after,
      &::before {
        content: "";
        width: 5rem;
        height: 8rem;
        display: block;
        position: absolute;
        top: -7.5rem;
        z-index: 1;
        @include background(null, "svg/footer-pattern-1.svg", bottom/100% no-repeat);
      }
      &::before {
        left: 0rem;
      }
      &::after {
        right: 0.1rem;
      }
      & > a::before {
        content: "";
        width: calc(100% - 9rem);
        left: 4.5rem;
        height: 2.4rem;
        top: -2rem;
        z-index: 1;
        @include background(null, "svg/footer-pattern-2.svg", left/2rem 2rem space);
        @extend %absolute;
      }
    }
    &-title {
      @include truncate(var(--_content-line), var(--_content-title), var(--_content-line-height));
      @extend %font-22-pb;
      @extend %pure-white-900;
      @extend %capitalize;
    }
    &-description {
      @include truncate(var(--_description-line), var(--_description-title), var(--_description-line-height));
      @extend %font-14-pm;
      @extend %pure-white-900;
      @extend %d-none;
    }
    &-meta {
      .meta-date {
        @extend %font-14-pm;
        @extend %primary-50;
      }
      .meta-category {
        top: 2.5rem;
        left: 1.5rem;
        @extend %font-14-pm;
        @extend %primary-50;
        @extend %absolute;
      }
    }
  }
  .item-type-icon {
    @extend %pos-center;
  }
  .preview-swiper {
    @extend %relative;
    @extend %w-100;
  }
  .swiper-button-next {
    right: 0;
    border-bottom-right-radius: 0;
  }
  .swiper-button-prev {
    right: 4.1rem;
  }
  .swiper-pagination {
    --swiper-pagination-bottom: 4.7rem;
    left: 0.5rem;
  }
  .icon-time-wrapper,
  .meta-author,
  .reaction-section,
  .action-btn,
  .article-commentbox,
  .thumbnail-swiper {
    @extend %d-none;
  }
}
@include mq(col-md) {
  .waf-showcase {
    padding-block: var(--space-5) var(--space-9);
    --_content-width: 41.2rem;
    --_content-line: 3;
    --_content-title: 32;
    --_content-line-height: 36;
    --swiper-button-bottom: 0;
    --swiper-button-width: 4.6rem;
    --swiper-button-height: 4.4rem;
    .img-box {
      border-radius: var(--full-radius);
    }
    .article {
      &-wrap {
        padding-top: var(--space-6);
        @include flex-config(flex, row-reverse, space-between, center);
      }
      &-thumbnail {
        width: 47%;
        flex-shrink: 0;
        margin-left: -23rem;
        &::before {
          display: none;
        }
        img {
          object-fit: cover;
        }
      }
      &-content {
        width: 50%;
        // width: var(--_content-width);
        padding-inline: var(--space-4);
        padding-block: var(--space-15) var(--space-12);
        margin-left: var(--space-2);
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        &::before,
        &::after {
          top: -7rem;
        }
        &::before {
          left: -1.4rem;
        }
        &::after {
          right: -1.1rem;
        }
        & > a::before {
          top: -1.5rem;
          left: 3.5rem;
          width: calc(100% - 7rem);
        }
      }
      &-description {
        display: flex;
      }
      &-meta {
        .meta-category {
          left: 2rem;
          top: 3.5rem;
        }
      }
    }
    .swiper-pagination {
      bottom: unset;
      top: 90%;
      left: 3rem;
      padding-left: var(--space-0);
      max-width: 30rem
    }
    .swiper-button-prev,
    .swiper-button-next {
      right: unset;
    }
    .swiper-button-prev {
      left: calc(var(--_content-width) - var(--space-18));
    }
    .swiper-button-next {
      border-bottom-right-radius: var(--half-radius);
      left: calc(var(--_content-width) - var(--space-9));
    }
  }
}
@include mq(col-lg) {
  .waf-showcase {
    --swiper-button-bottom: 5%;
    .swiper-pagination {
      top: 91%
    }
    .swiper-button-prev {
      left: calc(var(--_content-width) - var(--space-18));
    }
    .swiper-button-next {
      left: calc(var(--_content-width) - var(--space-9));
    }
    .article {
      &-thumbnail {
        width: 65%;
      }
      &-content {
        width: var(--_content-width);
      }
    }
  }
}
@include mq(col-xl) {
  .waf-showcase {
    .swiper-pagination {
      top: 83%
    }
  }
}
@include mq(col-xxl) {
  .waf-showcase {
    --swiper-button-bottom: 8%;
    .swiper-pagination {
      top: 80%
    }
  }
}