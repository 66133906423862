@use "../config/" as *;
.item-type-icon {
    width: 3rem;
    aspect-ratio: 1/1;
    z-index: var(--zindex-video-icon);
    @extend %flex-c-c;
    &::before {
        color: var(--c-white);
    }
}
// @property --per {
//     syntax: '<percentage>';
//     inherits: false;
//     initial-value: 0%;
// }
.item-type-video {
    --_progress-size: 5rem;
    // --_progress-size: 7.1rem;
    // --_progress-ring: 0.3rem;
    .item-type-icon {
        width: var(--_progress-size);
        height: var(--_progress-size);
        border-radius: 50%;
        // padding: var(--_progress-ring);
        place-items: center;
        // background: conic-gradient(var(--tertiary-600) var(--per, 100%), var(--primary-500) 0deg);
        box-shadow: 0rem 0rem 1.5rem var(--neutral-900);
        @extend %pos-center;
        @extend %circle-radius;
        @extend %flex-c-c;
        &::before {
            font-size: 1.8rem;
            @include icon(play, 14);
            @extend %accent-900-bg;
            @extend %pure-white-900;
            @extend %flex-c-c;
            @extend %circle-radius;
            @extend %w-100;
            @extend %h-100;
        }
    }
    &.swiper-slide-active {
        .item-type-icon {
            background: conic-gradient(var(--tertiary-600) var(--per, 100%), var(--primary-500) 0deg);
            animation: progress 3.5s linear 0.5s forwards;
        }
    }
}