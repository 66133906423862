@use "../config" as *;
.modal {
	&-window {
		display: flex;
		border: none;
		width: var(--window-inner-width);
		height: var(--window-inner-height);
		z-index: var(--z-modal);
		overflow: auto;
		@extend %neutral-100-bg;
		@include position(0, 0, 0, 0, fixed);
	}
}
.waf-modal {
	width: var(--window-inner-width);
	height: calc(var(--window-inner-height) - var(--header-height));
	z-index: var(--z-modal);
	overflow: auto;
	align-items: center;
	@extend %d-none;
	@extend %neutral-900-bg-5;
	@include position(var(--header-height), 0, 0, 0, fixed);
	&.active {
		@extend %flex;
	}
	.modal {
		&-wrapper {
			@extend %full-radius;
			@extend %py-6;
			@extend %px-2;
			@extend %mx-auto;
			@extend %w-90;
			@extend %pure-white-900-bg;
		}
		&-head {
			width: 12rem;
			height: 12rem;
			@extend %p-5;
			@extend %mx-auto;
			@extend %neutral-5-bg;
			@extend %flex-c-c;
			@extend %circle-radius;
		}
		&-body {
			@extend %mb-4;
			@extend %text-center;
		}
		&-footer {
			.footer-wrapper {
				@extend %gap-2;
				@extend %flex-c-n;
			}
		}
	}
	.btn-modal {
		flex: 1;
		height: 4.5rem;
		@extend %half-radius;
		.btn-text {
			@extend %neutral-800;
			@extend %uppercase;
			@extend %font-14-pb;
		}
	}
	.btn-outline {
		border: .1rem solid var(--neutral-800);
		@extend %transparent-bg;
	}
	.btn-fill {
		border: .1rem solid var(--tertiary-600);
		@extend %tertiary-600-bg;
	}
	.title {
		@extend %my-4;
		@extend %capitalize;
		@extend %font-24-pb;
		@extend %neutral-800;
	}
	.sub-title,
	.text {
		@extend %neutral-600;
		@extend %font-14-pr;
	}
	&.modal-avatar {
		.title {
			@extend %mt-0;
		}
	}
	.avatar {
		&-item {
			@extend %relative;
			&.active {
				.avatar-thumbnail {
					border: .1rem solid var(--accent-900);
				}
				&::before {
					content: '';
					width: 1.8rem;
					height: 1.8rem;
					@include background(null , "icons/selected.png", top / cover no-repeat);
					@include position(var(--space-2),0,null,null);
				}
			}
		}
		&-list {
			flex-wrap: wrap;
			row-gap: var(--space-3);
			column-gap: var(--space-1);
			@extend %flex-c-n;
		}
		&-thumbnail {
			width: 10rem;
			height: 10rem;
			border: .1rem solid transparent;
			@extend %neutral-200-bg;
			@extend %circle-radius;
			img {
				object-fit: contain;
				@extend %h-100;
				@extend %circle-radius;
			}
		}
	}
}
@include mq(col-md) {
	.waf-modal {
		.modal-wrapper {
			width: 85%;
			padding-inline: var(--space-6);
		}
		&.modal-avatar {
			.modal-wrapper {
				margin-top: 10rem;
			}
		}
	}
}
@include mq(col-md) {
	.waf-modal {
		.modal-wrapper {
			width: 40%;
		}
	}
}