@use "../config" as *;
.footer {
    &-top {
        @extend %p-2;
        @extend %pb-4;
        .client-head {
            @extend %my-5;
            @extend %flex-c-c;
            @extend %gap-3;
            &::after,
            &::before {
                content: "";
                flex: 1;
                height: .1rem;
                @extend %neutral-300-bg;
            }
        }
        .client-title {
            flex-shrink: 0;
            width: max-content;
            max-width: 85%;
            @extend %text-center;
            @extend %neutral-800;
            @extend %font-16-pb;
            @extend %uppercase;
        }
        .client-list-wrap {
            flex-wrap: wrap;
            @extend %px-3;
            @extend %flex-c-c;
            @extend %gap-6;
        }
        .client-logo {
            height: 8rem;
            width: auto;
            @extend %p-2;
            @extend %flex-c-c;
            @extend %neutral-100-bg;
        }
        .accordion {
            &-item {
                @extend %mb-6;
            }
            &-btn {
                @extend %flex-sb-c;
                @extend %w-100;
                @extend %p-3;
                @extend %half-radius;
                @extend %neutral-100-bg;
                &[aria-expanded=true] {
                    .btn-icon::after {
                        rotate: 0deg;
                    }
                }
                .btn-text {
                    flex: 1;
                    @extend %font-14-pb;
                    @extend %text-left;
                }
                .btn-icon {
                    width: 3rem;
                    height: 3rem;
                    @extend %relative;
                    @extend %tertiary-600-bg;
                    @extend %circle-radius;
                    &::after,
                    &::before {
                        content: "";
                        height: 0.15rem;
                        position: absolute;
                        inset: 0;
                        @extend %rounded-radius;
                        @extend %m-auto;
                        @extend %w-50;
                        @extend %neutral-900-bg;
                    }
                    &::after {
                        rotate: 90deg;
                        transition: 300ms rotate;
                    }
                }
            }
        }
    }
    &-middle {
        @extend %primary-700-bg;
        @extend %relative;
        @extend %hidden;
        .footer-link {
            display: inline-flex;
            vertical-align: middle;
            @extend %mx-1;
            &:not(:first-child):before {
                content: "";
                width: 0.1rem;
                height: 1rem;
                @extend %mr-2;
                @extend %neutral-50-bg-5;
            }
            &-list {
                padding-bottom: 4rem;
                @extend %h-100;
                @extend %p-3;
                @extend %text-center;
                &::after {
                    content: "";
                    width: calc(100% - 9rem);
                    left: 5rem;
                    height: 2rem;
                    position: absolute;
                    bottom: 0;
                    @include background(null, "svg/footer-pattern-2.svg", left/2rem 2rem space)
                }
            }
            &-anchor {
                @extend %neutral-50;
                @extend %font-12-pm;
            }
        }
        &::after,
        &::before {
            content: "";
            width: 4.5rem;
            height: 8rem;
            display: block;
            position: absolute;
            bottom: -1.5rem;
            @include background(null, "svg/footer-pattern-1.svg", bottom/100% no-repeat);
        }
        &::after {
            right: 0;
        }
        &::before {
            left: 0;
        }
    }
    &-bottom {
        @extend %primary-900-bg;
        .copyright {
            height: var(--footer-bottom-height);
            @extend %neutral-50;
            @extend %font-12-pm;
            @extend %p-3;
            @extend %text-center;
        }
        .powered-logo {
            @extend %h-100;
            @extend %neutral-50;
            @extend %font-12-pm;
            @extend %mb-1;
            @extend %text-center;
            &::after {
                content: "";
                width: 7rem;
                height: 2rem;
                @include background(null, "svg/powered-logo.svg", center / cover no-repeat);
                @extend %mx-auto;
                @extend %d-block;
            }
        }
    }
    &-logo {
        width: 9rem;
        @extend %mt-2;
        @extend %mx-auto;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .footer {
        &-wrap {
            padding-inline: 0;
        }
        &-top {
            .client {
                &-title {
                    font-size: 2rem;
                }
                &-list {
                    margin-bottom: var(--space-8)
                }
                &-head {
                    margin-block: var(--space-5) var(--space-8);
                }
            }
        }
        &-middle {
            .footer-link {
                &-anchor {
                    font-size: 1.3rem;
                }
            }
        }
        &-bottom {
            .copyright {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.3rem;
            }
            .powered-logo {
                display: flex;
                align-items: center;
                gap: var(--space-2);
                &::after {
                    flex-shrink: 0;
                }
            }
        }
        &-logo {
            width: max-content;
            margin: 0;
        }
    }
}