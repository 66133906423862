@use "../config/" as *;
.waf-scorestrip {
    &.widget-layout-08 {
        padding-bottom: var(--space-14);
        .accordion-head,
        .swiper-button,
        .countdown-wrapper,
        .short-name,
        .run-rate {
            @extend %d-none;
        }
        .live {
            --_status-color: var(--accent-900);
            .card-head {
                .status {
                    @extend %flex-n-c;
                    @extend %gap-1;
                    &:before {
                        content: '';
                        width: 1.5rem;
                        height: 1.5rem;
                        @extend %flex;
                        @include background(null , "svg/live-blinker.svg", center / cover no-repeat);
                    }
                }
            }
        }
        .recent {
            --_status-color: var(--neutral-600);
        }
        .upcoming {
            --_status-color: var(--secondary-800);
            // .btn-scorecard {
            //     @extend %d-none;
            // }
        }
        .waf-head {
            @extend %pb-3;
            .head-tab {
                bottom: var(--space-5);
                @extend %pos-x-center;
            }
        }
        .card {
            &-head {
                flex-wrap: wrap;
                row-gap: var(--space-2);
                @extend %mb-4;
                @extend %pt-7;
                @extend %flex-c-c;
            }
            &-content {
                height: 10rem;
                @extend %mb-4;
            }
            &-label {
                @extend %d-none;
            }
            &-venue {
                order: 1;
                font-weight: 500;
                @extend %text-center;
                @extend %w-100;
                @extend %neutral-700;
                @extend %capitalize;
                @include truncate(2, 14);
            }
            &-number {
                .text,
                .number {
                    @extend %neutral-700;
                    @extend %capitalize;
                    @extend %font-14-pb;
                }
            }
            &-list {
                width: max-content;
                @extend %flex;
                @extend %gap-3;
            }
            &-item {
                flex-shrink: 0;
                width: min(calc(80vw - .75rem), 30rem);
                height: 40rem;
                @extend %flex-column;
                @extend %relative;
                @extend %p-4-2;
                @extend %half-radius;
                &:before {
                    content: '';
                    height: .4rem;
                    border-radius: var(--half-radius) var(--half-radius) 0 0;
                    @extend %w-100;
                    @extend %pos-tl;
                }
                &::after {
                    content: '';
                    border: .1rem solid clr(neutral-800, 2);
                    z-index: var(--z-scorestrip-border);
                    mask-image: linear-gradient(var(--neutral-200) 60%, transparent);
                    pointer-events: none;
                    @extend %half-radius;
                    @extend %pos-tl;
                    @extend %w-100;
                    @extend %h-100;
                    @extend %neutral-200-bg;
                }
                .status,
                &:before {
                    background-color: var(--_status-color);
                }
            }
            &-footer-text {
                @extend %text-center;
                @extend %neutral-700;
                @extend %font-14-pm;
            }
            &-footer {
                height: 11rem;
                @extend %flex-column-sb-n;
                @extend %mt-auto;
                @extend %relative;
                @extend %pt-3;
                &:before {
                    content: '';
                    height: .1rem;
                    pointer-events: none;
                    background: linear-gradient(90deg, clr(neutral-800, 0) 0%, clr(neutral-800, 5) 24.4%, var(--neutral-800) 47.45%, clr(neutral-800, 4) 76.68%, clr(neutral-800, 0) 99.74%);
                    @extend %pos-tl;
                    @extend %w-100;
                }
            }
        }
        .team {
            width: 45%;
            @extend %gap-1;
            @extend %flex;
            &-wrapper {
                @extend %flex-sb-n;
            }
            &-b {
                flex-direction: row-reverse;
                .team-info {
                    align-items: flex-end;
                    @extend %text-right;
                }
                .team-data {
                    @extend %text-right;
                }
            }
            &-info {
                width: 5rem;
                @extend %gap-2;
                @extend %flex-column;
            }
            &-image {
                width: 5rem;
                height: 5rem;
                flex-shrink: 0;
                @extend %circle-radius;
                @extend %neutral-50-bg-5;
                @extend %p-1;
                @extend %flex-c-c;
            }
            &-name {
                width: 12rem;
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-12-pb;
            }
            &-won {
                .score {
                    @extend %accent-900;
                }
                .overs {
                    @extend %neutral-300;
                }
            }
        }
        .status {
            border-radius: .2rem;
            top: var(--space-4);
            @extend %pos-x-center;
            @extend %p-1-2;
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-14-pb;
        }
        .score-strip-slider {
            overflow-x: auto;
            @extend %px-2;
            @extend %mx-2-neg;
        }
        .date-time {
            @extend %pl-2;
            @extend %relative;
            &:before {
                content: '';
                left: var(--space-1);
                width: .2rem;
                @extend %h-80;
                @extend %neutral-700-bg;
                @extend %pos-y-center;
            }
            .meta {
                @extend %neutral-700;
                @extend %capitalize;
                @extend %font-14-pb;
            }
        }
        .btn-group {
            @extend %mt-2;
        }
        .btn-more {
            border: .1rem solid var(--secondary-800);
            height: 4.8rem;
            transition: background-color .3s linear;
            @extend %flex-c-c;
            @extend %w-100;
            @extend %half-radius;
            .btn-text {
                @extend %secondary-800;
                @extend %font-14-pb;
                @extend %uppercase;
            }
            &:hover {
                border-color: var(--tertiary-600);
                @extend %tertiary-600-bg;
            }
        }
        .time-text {
            border-radius: .3rem;
            padding: .3rem;
            border-radius: .3rem;
            width: max-content;
            @extend %accent-900-bg;
            @extend %font-12-pb;
            @extend %uppercase;
            @extend %pure-white-900;
        }
        .match-time {
            width: 10%;
            isolation: isolate;
            @extend %relative;
            @extend %flex-c-c;
            &:before {
                content: '';
                width: .1rem;
                pointer-events: none;
                background: linear-gradient(180deg, clr(neutral-800, 0) 0%, clr(neutral-800, 5) 24.4%, var(--neutral-800) 47.45%, clr(neutral-800, 4) 76.68%, clr(neutral-800, 0) 99.74%);
                top: 0;
                z-index: var(--z-scorestrip-border);
                @extend %h-100;
                @extend %pos-x-center;
            }
        }
        .score {
            @extend %font-16-pb;
            @extend %neutral-700;
        }
        .overs {
            @extend %font-12-pm;
            @extend %neutral-700;
        }
        .super-over {
            .team {
                &-data {
                    @extend %flex;
                    &:last-child {
                        .overs,
                        .score {
                            @extend %neutral-700;
                        }
                    }
                }
                &-b {
                    .team-data {
                        flex-direction: row-reverse;
                    }
                }
                &-won {
                    .score {
                        @extend %neutral-300;
                    }
                    .team-data {
                        &:last-child {
                            .score {
                                @extend %accent-900;
                            }
                        }
                    }
                }
            }
            .score,
            .overs {
                @extend %neutral-300;
            }
            .score {
                font-size: 1.4rem;
            }
            .overs {
                font-size: 1.2rem;
            }
        }
        &.waf-shimmer {
            .date-time {
                height: 2rem;
                width: 10rem;
                @include shimmer;
                .meta {
                    @extend %font-0;
                }
                &::before {
                    @extend %d-none;
                }
            }
            .team {
                &-data {
                    width: 5.5rem;
                    height: 4.4rem;
                    @include shimmer;
                    border-radius: var(--half-radius);
                    .score,
                    .overs {
                        @extend %font-0;
                    }
                }
                &-name {
                    width: 12rem;
                    height: 3rem;
                    @extend %font-0;
                    &.short-name {
                        @extend %d-none;
                    }
                }
            }
            .card {
                &-number {
                    width: 7rem;
                    ;
                    height: 2rem;
                    @include shimmer;
                    .text,
                    .number {
                        @extend %font-0;
                    }
                }
                &-venue,
                &-footer-text {
                    @extend %w-100;
                    @extend %font-0;
                    @include shimmer;
                }
                &-venue {
                    height: 4.4rem;
                }
                &-footer-text {
                    height: 3.6rem;
                }
            }
            .status,
            .btn-more,
            .team-name,
            .time-text {
                @include shimmer;
            }
            .time-text {
                width: 2.2rem;
                height: 2.1rem;
                @extend %font-0;
            }
            .status {
                height: 2.8rem;
                width: 12rem;
                @extend %font-0;
                @include position(var(--space-4), null, null, 50%);
            }
            .btn-more {
                .btn-text {
                    @extend %font-0;
                }
            }
        }
    }
}
@media screen and (max-width: 350px) {
    .waf-scorestrip {
        &.widget-layout-08 {
            .card-item {
                width: max(calc(90vw - .75rem), 30rem);
                height: 43rem;
            }
        }        
    }
}
@include mq(col-md) {
    .waf-scorestrip {
        &.widget-layout-08 {
            .card-item {
                width: calc(40vw - .75rem);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-scorestrip {
        &.widget-layout-08 {
            padding: var(--space-17) 0 var(--space-6);
            .primary-tabs {
                @include position(var(--space-6), max(var(--container-white-space), 0rem), null, null);
            }
            .accordion-body {
                display: flex;
            }
            .waf-head {
                width: 20%;
                height: 100%;
                display: flex;
                align-self: center;
                .head-wrap {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--space-3);
                }
                .head-tab {
                    translate: unset;
                    position: unset;
                }
            }
            .waf-body {
                width: 80%;
            }
            .card {
                &-list {
                    width: 100%;
                }
                &-item {
                    width: calc(33.33% - var(--space-2));
                }
            }
            .score-strip-slider {
                overflow: hidden;
                margin-inline: var(--space-0);
                padding-inline: var(--space-0);
            }
        }
    }
}
@include mq(col-xl) {
    .waf-scorestrip {
        &.widget-layout-08 {
            .waf-head {
                width: 25%;
            }
            .waf-body {
                width: 75%;
            }
        }
    }
}