@use "../config/" as *;

.waf-shop {
    @extend %py-9;
    @include background(null , "pattern/mobile/shop-bg.png", top / cover no-repeat);
    .waf-head {
        .title {
            @extend %capitalize;
            @extend %font-18-pb;
            @extend %pure-white-900;
        }
        .head-tab a {
            width: 8rem;
        }
    }
    .article {
        &-list {
            overflow-x: auto;
            @extend %mx-2-neg;
            @extend %px-2;
            @extend %gap-3;
            @extend %flex;
        }
        &-item {
            width: 75%;
            flex-shrink: 0;
            cursor: pointer;
            @extend %relative;
        }
        &-title {
            font-weight: 700;
            @include truncate(1,14);
            @extend %neutral-800;
        }
        &-content {
            @extend %w-100;
            @extend %pos-bl;
            @extend %p-2;
            @extend %half-radius;
            @extend %gap-2;
            @extend %flex-column;
            @extend %pure-white-900-bg;
        }
        &-thumbnail {
            .img-box {
                transition: border-radius .4s linear;
                @extend %neutral-300-bg;
            }
        }
    }
    .price {
        @extend %font-12-pm;
        @extend %neutral-800;
        &-off {
            @extend %success-900;
            @extend %font-12-pm;
        }
    }
}
@include mq(col-md) {
    .waf-shop {
        @include background(null , "pattern/shop-bg.png", top / cover no-repeat);
        .waf-head {
            .title {
                font-size: 3.2rem;
            }
            .head-tab a {
                width: 11rem;
            }
        }
        .article {
            &-list {
                height: 23rem;
            }
            &-item {
                width: 30%;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-shop {
        .article {
            &-list {
                margin-inline: 0;
                padding-inline: 0;
                overflow: hidden;
                transition: gap .4s linear;
                height: 30rem;
                &:hover {
                    gap: var(--space-0); 
                    .img-box {
                        border-radius: 0;
                    }
                    .article-content {
                        border-radius: var(--half-radius) var(--half-radius) 0 0;
                    }   
                }
            }
            &-item {
                width: calc(25% - 1.1rem);
                transition: width .4s linear;
                &:hover {
                    width: calc(25% + 3.3rem);
                    .article-content {
                        opacity: 1;
                    }
                }
            }
            &-content {
                opacity: 0;
                transition: opacity .4s linear;
            }
        }
    }
}